.k-product-range-checkbox input[type="checkbox"] {
  display: none;

  + label {
    overflow: hidden;
    height: 6rem;
    user-select: none;
    transition: border 0.2s ease-in-out;

    h5 {
      color: $text-muted;
    }

    img {
      width: 7rem;
      display: block;
      transition: all 0.2s ease-in-out;
      opacity: 0.5;
    }
  }

  &:hover + label {
    @extend .shadow-sm;

    h5 {
      color: $body-color;
    }

    img {
      opacity: 1;
    }
  }

  &:checked + label {
    border: 1px solid $primary;
    @extend .shadow-sm;

    h5 {
      color: $primary;
    }

    img {
      opacity: 1;
    }
  }
}

.k-product-range-wrapper {
  max-width: 83rem;
  padding: 1rem;
  margin-inline: auto;

  > * + * {
    margin-top: 1rem;
  }
}

.k-filter-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(16.25rem, 1fr));


  > input[type="checkbox"] {
    display: none;

    &:checked + label {
      border: 3px solid var(--k-brand);
      @extend .shadow-sm;

      h4 {
        color: var(--k-brand);
      }

    }
  }
}

.k-filter-card {
  display: grid;
  background: $kelvion-black;
  color: $kelvion-white;
  height: 10.8rem;
  cursor: pointer;

  &--aspect-ratio {
    height: auto;
    aspect-ratio: 5 / 3;
  }

  > * {
    grid-column: 1;
    grid-row: 1;
  }

  img {
    width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  &__body {
    z-index: 1;
    margin: 0.875rem;

    h4 {
      user-select: none;
    }

    ul {
      list-style-type: none;
      padding: 0;
      font-size: 0.875rem;
      font-weight: bold;
      margin-top: 0.875rem;
    }

    li + li {
      margin-top: 0.375rem;
    }
  }

  &__buttons {
    width: 46px;
    right: 0;
    position: absolute;
  }

  &__button {
    width: 45px;
    height: 45px;
    left: 0;
    position: absolute;
    box-shadow: 0 4px 4px $kelvion-black;
    border-radius: 9999px;
    border: 1px $kelvion-white solid;
  }

  &__air-direction-button {
    top: 52px;
    background: $kelvion-dark-gray;
  }

  &__information-frame {
    width: 45px;
    height: 45px;
    left: 0;
    top: 104px;
    position: absolute;
  }

  &__information-button {
    top: 0;
    background: $kelvion-yellow;
  }

  &__information-label {
    left: 19.5px;
    top: 8.5px;
    position: absolute;
    color: $kelvion-black;
    font-size: 24px;
    font-weight: 900;
    text-transform: lowercase;
    line-height: 28.80px;
    word-wrap: break-word;
  }

  &__cooler-design-button {
    top: 0;
    background: $kelvion-gray;
  }

  &__headline {
    width: 233px;
    height: 96px;
    left: 0;
    top: 0;
    position: absolute;
    color: $kelvion-white;
    font-size: 24px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 28.80px;
    word-wrap: break-word;
  }
}

.k-filter-card__body {
  position: relative;
}
