body {
  font-variant-numeric: tabular-nums;
}

.k-font-sm {
  font-size: 85%;
}

.k-font-xs {
  font-size: 75%;
}

.k-translate-wrapper {
  position: relative;
}

.k-translate-icon {
  position: absolute;
  top: -0.2rem;
  right: -1.3rem;
}

.k-card-legend {
  position: absolute;
  background-color: #fff;
  padding: 0 $spacer;
  top: -14px;
  left: 5px;
  font-weight: bold;
  color: $text-muted;
}

.k-bg-yellow {
  background-color: $kelvion-yellow;
}

.k-bg-black {
  background-color: $black;
}

.k-text-yellow {
  color: $kelvion-yellow;
}

.k-card-admin {
  margin-bottom: $spacer * 1.5;
  border-color: #b7b7b7;
  border-size: 3px;

  > table {
    margin-bottom: 0;
  }
}

.nav-tabs-parameters .nav-item {
  margin-bottom: -9px;
}

.k-form-control-highlight {
  background-color: #dff5ff !important;
}

.k-table-no-border-top {
  tr:first-child {
    td {
      border-top: none;
    }
  }
}

.k-sticky-bottom {
  position: sticky;
  bottom: 0;
  background-color: $black;
  color: $white;
  z-index: 6;
}

.k-part-selection-margin {
  margin-bottom: -1.25rem;
}

.k-part-selection-resulttable {
  max-height: 363px;
  overflow: auto;
}


$rotate-degrees: 90, 180, 270;
@each $degree in $rotate-degrees {
  .k-rotate-#{$degree} {
    transform: rotate(#{$degree}deg);
  }
}

.navbar-text {
  padding: 0.5rem;
}

.nav-link-text {
  margin-right: 0.5rem;
}

.k-nav-link {
  color: $navbar-dark-color;

  &:hover,
  &:focus {
    color: $navbar-dark-hover-color;
  }

  &.disabled {
    color: $navbar-dark-disabled-color;
  }
}

.icon-button {
  cursor: pointer;
  display: contents;

  :focus {
    outline: none;
    box-shadow: none;
  }
}

a.icon-button {
  display: inline;
}

.dropdown-menu-favorites {
  width: 100%;
}

@include media-breakpoint-up(md) {
  .dropdown-menu-favorites {
    width: 500px;
  }
}

@include media-breakpoint-down(md) {
  .btn-sm-block {
    width: 100% !important;
    display: block !important;
    margin-bottom: 5px;
  }
}

.hot {
  input, select, input.jdropdown-header {
    border-left: 2px solid $kelvion-red-2 !important;
  }
}

.cold {
  input, select, input.jdropdown-header {
    border-left: 2px solid $kelvion-blue-2 !important;
  }
}

.tooltip {
  pointer-events: none;
}

.k-product-range-checkbox[data-bs-toggle="tooltip"] * {
  pointer-events: none;
}

.page-header {
  color: var(--k-page-headline-color);
  text-transform: var(--k-headline-transform);
  font-weight: 900;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  color: #333;
  margin: 0;
}

header {
  background: #000;
  height: 3.75rem;
}

h2 {
  font-weight: 900;
  margin: 0.5em 0;
}

.progress-area {
  height: 3rem;
  background: #fff;
  border-bottom: 1px solid Rgba(0 0 0 / 10%);
}

.wrapper {
  max-width: 82.5rem;
  margin: 0 auto;
  padding: 1em;
}

a {
  font-weight: 600;
  text-decoration: none;
}

a.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}

@mixin k-button-variant(
  $background,
  $border,
  $color: color-contrast($background),
  $hover-background: if($color == $color-contrast-light, shade-color($background, $btn-hover-bg-shade-amount), tint-color($background, $btn-hover-bg-tint-amount)),
  $hover-border: if($color == $color-contrast-light, shade-color($border, $btn-hover-border-shade-amount), tint-color($border, $btn-hover-border-tint-amount)),
  $hover-color: color-contrast($hover-background),
  $active-background: if($color == $color-contrast-light, shade-color($background, $btn-active-bg-shade-amount), tint-color($background, $btn-active-bg-tint-amount)),
  $active-border: if($color == $color-contrast-light, shade-color($border, $btn-active-border-shade-amount), tint-color($border, $btn-active-border-tint-amount)),
  $active-color: color-contrast($active-background),
  $disabled-background: $background,
  $disabled-border: $border,
  $disabled-color: color-contrast($disabled-background)
) {
  --#{$prefix}btn-color: #{$color};
  --#{$prefix}btn-bg: #{$background};
  --#{$prefix}btn-border-color: #{$border};
  --#{$prefix}btn-hover-color: #{$hover-color};
  --#{$prefix}btn-hover-bg: #{$hover-background};
  --#{$prefix}btn-hover-border-color: #{$hover-border};
  --#{$prefix}btn-active-color: #{$active-color};
  --#{$prefix}btn-active-bg: #{$active-background};
  --#{$prefix}btn-active-border-color: #{$active-border};
  --#{$prefix}btn-active-shadow: #{$btn-active-box-shadow};
  --#{$prefix}btn-disabled-color: #{$disabled-color};
  --#{$prefix}btn-disabled-bg: #{$disabled-background};
  --#{$prefix}btn-disabled-border-color: #{$disabled-border};

}

.btn-primary {
  //@include button-variant($kelvion-yellow, $kelvion-yellow, $black, darken($kelvion-yellow, 5%), lighten($kelvion-yellow, 10%), darken($kelvion-yellow, 30%));

  @include k-button-variant(
                  var(--k-btn-primary-bg), // $background
                  var(--k-btn-primary-bg), // $border
                  var(--k-btn-primary-color), // $color: color-contrast($background)
                  var(--k-btn-primary-hover-bg), // $hover-background
                  var(--k-btn-primary-bg), // $hover-border
                  var(--k-btn-primary-color), // $hover-color:
                  var(--k-btn-primary-active-bg), // $active-background: if($color == $color-contrast-light, shade-color($background, $btn-active-bg-shade-amount), tint-color($background, $btn-active-bg-tint-amount)),
                  var(--k-btn-primary-bg), // $active-border: if($color == $color-contrast-light, shade-color($border, $btn-active-border-shade-amount), tint-color($border, $btn-active-border-tint-amount)),
                  var(--k-btn-primary-color), // $active-color: color-contrast($active-background),
                  var(--k-btn-primary-bg), // $disabled-background: $background,
                  var(--k-btn-primary-bg), // $disabled-border: $border,
                  var(--k-btn-primary-color) // $disabled-color: color-contrast($disabled-background)
  );

  font-weight: 700;
}

.k-sticky-under-navigation {
  top: 59px;
  z-index: 2;
}

.k-teaser-headline {
  margin-bottom: 0.2em;
}

.k-sidebar {
  margin-top: 3rem;
}

.k-favorite-star {
  vertical-align: baseline;
}

.display-content {
  display: contents;
}

.form-control, .input-group-text, .form-select, .form-select-sm {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.k-product-range-checkbox {
  border-radius: 0;
}

h1, h2, h3, h4, h5, h6 {
  text-transform: var(--k-headline-transform);
  font-weight: 900;
}

.k-text-highlight {
  color: var(--k-text-highlight);
}

.k-navbar {
  background: var(--k-navbar-bg);
  background-color: var(--k-navbar-bg);
}

.k-navbar-environment-warning {
  @media screen and (min-width: 640px) {
    height: 40px;
  }
  @media not screen and (min-width: 640px) {
    height: 72px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.k-tender-row p {
  margin-bottom: 0;
}

.k-edit-pencil,
.k-edit-pencil:hover,
.k-edit-pencil:active,
.k-edit-pencil:visited,
.k-edit-pencil:link {
  color: $kelvion-black;
  text-decoration: none;
}

.footer {
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
}

.h-31 {
  min-height: 31px;
}

.k-required:after {
  content: "*";
  color: $kelvion-red-3;
  padding-left: 2px;
}

.invalid-tooltip {
  z-index: 6;
}

.landing-ahri-cert-logo {
  bottom: 0.5rem !important;
  right: 0.5rem !important;
}

.landing-fb-text {
  bottom: 0.5rem !important;
  left: 0.5rem !important;
  font-size: x-large;
  color: black;
  font-weight: bold;
}

.align-text-left {
  text-align: left;
}

.k-template-dropdown {
  min-width: 300px;
}

.k-input-with-icon {

  input[type=text] {
    font-size: $font-size-sm;
  }

  button {
    border: 1px solid $input-border-color;
    background: $kelvion-white;
    color: $kelvion-black;
  }

}

.k-play-button {
  fill: #565656;
}

.outline-none, .outline-none:focus {
  outline: none;
  box-shadow: none;
}

hr {
  color: #EFEFEF;
  opacity: unset;
}

// fix for direct search on parameter page shown as bootstrap col-12
.k-direct-search-label {
  @media screen and (min-width: 640px) {
    padding-right: calc(var(--bs-gutter-x) * 1);
  }
}

.k-direct-search-nested {
  @media screen and (min-width: 640px) {
    padding-left: unset;
  }
}

.k-phe-custom-fluid-button {
  line-height: 0.5 !important;
}

.k-phe-multi-stage-graphic {
  height: 700px;
  width: 100%;
  overflow: scroll;
}

.k-result-list-material-color {
  background-color: var(--color) !important;
}

.blocking-overlay {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  background-color: whitesmoke;
  opacity: 50%;
  width: 100%;
  height: 100%;
}

html {
  scroll-behavior: auto !important;
}
